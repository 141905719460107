import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import styled from "styled-components"

import Seo from "@components/Seo"
import { PageHero } from "@components/sections/heros/PageHero"
import { scrollTo } from "@components/utils/helpers"
import Heading from "@components/ui/Heading"

const ContentStyled = styled.section`
  padding: 30px 0;

  .passion_panel_question_text_container {
    padding-bottom: 50px;
  }

  .passion_panel_question_text_container .passion_panel_answer_text {
    padding-left: 35px;
  }

  .passion_panel_question_link_container {
    margin-bottom: 15px;
  }

  /* hack to fix the anchor tag headbutting the top of the screen */

  .passion_panel_question_text_container::before {
    display: block;
    content: " ";
    margin-top: -285px;
    height: 285px;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
  }

  .sidebarLeft {
    position: sticky;
    padding-top: 60px;
    top: 60px;
  }

  .page-template-template-passion-panel-child-upcoming
    .content_title_center:after,
  .content_title_center:after {
    display: none;
  }

  .page-template-template-passion-panel-child-upcoming
    .form-section
    .form-title {
    display: none;
  }

  .passion_panel_question_link_container .passion_panel_question_anchor {
    font-size: 15px;
  }

  .passion_panel_question_link_container #return-to-the-top {
    font-size: 17px;
  }

  .passion_panel_question_link_container #return-to-passion-panel {
    font-size: 12px;
  }

  .passion_panel_question_link_container #return-to-passion-panel:before {
    padding: 0 5px 0 0;
    color: $brand_colour;
    content: "\\00bb";
    font-size: 12px;
  }

  .pd-breadcrumbs,
  .caption {
    display: none;
  }
`

export default function PassionPanelPreviousPage({ data }) {
  const page = data.allWpPage.nodes[0]

  const breadcrumbs = page.familyTree?.reverse().map(branchPage => {
    return {
      url: branchPage.uri,
      title: branchPage.title,
      isHighlight: true,
    }
  })

  const qas = page.acfPassionPanelQA?.passionPanelQAndA

  const returnToTop = () => scrollTo(document.querySelector(`#main_wrapper`))

  return (
    <>
      <Seo {...page.seo} />
      <Helmet bodyAttributes={{}} />

      <PageHero
        pageTitle={page.title}
        uri={page.uri}
        subTitle={page.date}
        breadcrumbs={breadcrumbs}
        heroImage={page.featuredImage?.node}
      />

      <ContentStyled>
        <div className="container">
          <div className="row">
            <div className="sidebar sidebarLeft col-md-4">
              <div className="passion_panel_question_link_container">
                <div
                  className="link pink passion_panel_question_anchor"
                  id="return-to-the-top"
                  onClick={returnToTop}
                  onKeyPress={returnToTop}
                  role="button"
                  tabIndex="0"
                >
                  Back to the Top
                </div>
              </div>
              {qas &&
                qas.map(
                  (
                    { questionId, questionLinkText, questionText, answerText },
                    key
                  ) => {
                    const scrollToContent = () =>
                      scrollTo(document.querySelector(`#${questionId}`))

                    return (
                      <div
                        className="passion_panel_question_link_container"
                        key={key}
                      >
                        <div
                          className="link pink passion_panel_question_anchor"
                          onClick={scrollToContent}
                          onKeyPress={scrollToContent}
                          role="button"
                          tabIndex="0"
                          aria-label="Scroll to panel"
                          dangerouslySetInnerHTML={{ __html: questionLinkText }}
                        />
                      </div>
                    )
                  }
                )}
            </div>
            <div className="col-md-8">
              <div dangerouslySetInnerHTML={{ __html: page.content }} />
              {qas &&
                qas?.map(({ questionId, questionText, answerText }, key) => {
                  return (
                    <div
                      className="passion_panel_question_text_container"
                      id={questionId}
                      key={key}
                    >
                      <Heading
                        node="h3"
                        className="passion_panel_question_text"
                      >
                        <span
                          dangerouslySetInnerHTML={{ __html: questionText }}
                        />
                      </Heading>
                      <p
                        className="dark passion_panel_answer_text"
                        dangerouslySetInnerHTML={{ __html: answerText }}
                      />
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </ContentStyled>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    allWpPage(filter: { slug: { eq: $slug } }) {
      nodes {
        id
        uri
        title
        link
        content
        date(formatString: "MM/DD/YYYY")
        seo {
          schema {
            raw
          }
          canonical
          cornerstone
          focuskw
          metaDesc
          metaKeywords
          metaRobotsNofollow
          metaRobotsNoindex
          opengraphAuthor
          opengraphDescription
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphPublisher
          opengraphSiteName
          opengraphImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FIXED)
              }
            }
          }
          opengraphTitle
          opengraphType
          opengraphUrl
          title
          twitterDescription
          twitterTitle
        }
        parent: wpParent {
          node {
            uri
            ... on WpPage {
              id
              title
            }
          }
        }
        familyTree {
          ... on WpPage {
            id
            uri
            title
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        acfHeroImage {
          pageherodata {
            blurb
            subtitle
            title
            heroImage {
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    quality: 85
                    transformOptions: { cropFocus: CENTER }
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
        acfPassionPanelQA {
          passionPanelQAndA {
            questionId
            questionLinkText
            questionText
            answerText
          }
        }
      }
    }
  }
`
